@import 'styles/base/_variables.less';

.bgWrap {
  background-image: url('../../../assets/images/mainbg.webp');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 85px;
}

.imgBox {
  filter: drop-shadow(2px 2px 10px rgba(35, 51, 79, 0.1))
    drop-shadow(4px 4px 10px rgba(35, 51, 79, 0.1));
  overflow: hidden;
  border: 1px solid rgba(@white, 0.5);
  border-radius: @border-radius-base;
  margin-bottom: 24px;
}

.indentBox {
  @media (min-width: @breakpoint-lg) {
    padding: 32px 0;
  }
}

.emptyBox {
  background-color: @gray2;
  color: @gray3;
  border-radius: 12px;
  position: relative;
  padding-bottom: 127%;
  margin-bottom: 20px;

  @media (min-width: @breakpoint-lg) {
    margin-bottom: 0;
  }

  div {
    position: absolute;
    top: 50%;
    text-align: center;
    font-weight: @fontWeightBold;
    width: 100%;
    transform: translateY(-50%);
    font-size: 40px;

    @media (min-width: @breakpoint-lg) {
      font-size: 52px;
    }
  }
}

.contactSection {
  padding-bottom: 20px;

  @media (min-width: @breakpoint-lg) {
    padding: 50px 0 120px;
  }
}

.imageWrap {
  border-radius: @border-radius-base;
  filter: drop-shadow(0px 2px 4px rgba(35, 51, 79, 0.06))
    drop-shadow(4px 4px 20px rgba(35, 51, 79, 0.06));
  overflow: hidden;
}

.image {
  padding-bottom: 132%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.tag {
  display: inline-block;
  color: @white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;
  border-radius: 6px;
  padding: 4px 6px;
  background-color: @green1;
  vertical-align: bottom;
  margin: 0 4px;
}
