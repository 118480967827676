@import 'styles/base/_variables.less';

.contact {
  display: flex;
  width: 100%;

  &Img {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border: 1px solid @gray2;
    border-radius: @border-radius-base;
    overflow: hidden;
    position: relative;

    @media (min-width: @breakpoint-xs) {
      width: 130px;
      height: 130px;
    }

    @media (min-width: @breakpoint-md) {
      width: 150px;
      height: 150px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &Info {
    padding-left: 16px;
    flex-grow: 1;
    min-width: 0;
  }

  &Name {
    font-size: 18px;
    line-height: 1.1;
    font-weight: @fontWeightBold;
    color: @blue1;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: @breakpoint-md) {
      font-size: 22px;
      line-height: 27px;
    }
  }

  &Company {
    font-weight: @fontWeightSemiBold;
    color: @red1;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 2px;
    word-break: break-word;
  }

  &Broker {
    font-size: 12px;
    font-weight: @fontWeightBold;
    line-height: 17px;
  }

  &Dre {
    font-size: 10px;
    font-weight: @fontWeightMedium;
    line-height: 15px;
  }
}

.contactList {
  li {
    line-height: 1;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  a {
    font-size: 12px;
    font-weight: @fontWeightSemiBold;
    line-height: 16px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  i {
    font-size: 18px;
    vertical-align: text-bottom;
    padding-right: 5px;
  }
}
