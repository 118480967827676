@import 'styles/base/_variables.less';

.contactSubtitle {
  display: inline-block;
  font-weight: @fontWeightMedium;
  font-size: 12px;
  line-height: 18px;
  color: @gray5;
  border-bottom: 2px solid @gray3;
  margin-bottom: 8px;
}

.contactText {
  font-size: 12px;
  line-height: 18px;
  color: @gray5;
}
