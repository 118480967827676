@import 'styles/base/_variables.less';

.scrollBox {
  max-height: 516px;
  overflow-y: scroll;
}

.title {
  &:global(.h5) {
    margin-bottom: 0;
  }

  & + .subtitle {
    padding-top: 8px;
  }
}

.empty {
  padding: 16px 0;
  font-size: 16px;
  font-weight: @fontWeightSemiBold;
  line-height: 24px;
  color: @gray4;

  i {
    font-size: 22px;
    padding-right: 8px;
    vertical-align: text-bottom;
  }
}

.subtitle {
  font-size: 10px;
  font-weight: @fontWeightBold;
  letter-spacing: 0.1em;
  line-height: 13px;
  text-transform: uppercase;
  color: @gray4;

  & + .bidList {
    margin-top: 5px;
  }
}

.bidItem {
  font-size: 14px;
  line-height: 21px;
  font-weight: @fontWeightBold;
  color: @gray4;

  &.bidItemCurrent {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 17px;
  }

  &.bidItemInitial {
    color: @gray5;
    padding: 14px 0 16px;
    border: solid @gray2;
    border-width: 1px 0;
  }

  &Icon {
    padding-right: 4px;
  }

  &Price {
    color: @main-text;
  }
}

.bidList {
  li {
    padding: 14px 0 16px;
    border-bottom: 1px solid @gray2;

    &:first-child {
      padding-top: 10px;
    }
  }

  & + .bidItemInitial {
    border-top: 0;
  }
}

.btnWrap {
  padding: 24px 0 8px;
}

.shoBtnBox {
  padding: 14px 0 16px;
}

.showBtn {
  color: @gray5;
  font-weight: 700;
  font-size: 14px;
  height: auto;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    color: @primary-color;
  }
}
