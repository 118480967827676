@import 'styles/base/_variables.less';

.price {
  font-weight: @fontWeightBold;

  strong {
    color: @gray4;
    padding-right: 4px;
  }
}

.label {
  font-weight: @fontWeightSemiBold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: @gray4;
  text-transform: uppercase;
}
