@import 'styles/base/_variables.less';

.spinWrap {
  text-align: center;
  margin-top: 20%;
}

.pageHead {
  margin-bottom: 32px;
}

.wrap {
  position: relative;
  margin-bottom: 30px;
}

.backBtn {
  position: absolute;
  top: 24px;
  left: 15px;
  z-index: 1;

  @media (min-width: @breakpoint-lg) {
    left: 24px;
  }
}

.container {
  padding-top: 77px;
}

.timerBox {
  margin-bottom: 15px;
}

.mainTitle {
  margin-bottom: 6px;
}

.info {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 10px;

  @media (min-width: @breakpoint-lg) {
    margin-bottom: 40px;
  }

  li {
    padding: 0 8px;
    color: @main-text;
    font-size: 16px;
    font-weight: @fontWeightMedium;
    line-height: 22px;
  }

  i {
    font-size: 20px;
    color: @gray4;
    padding-right: 4px;
  }
}

.price {
  font-size: 30px;
  font-weight: @fontWeightBold;
  line-height: 42px;
  margin-bottom: 4px;

  strong {
    color: @gray4;
    font-size: 32px;
    padding-right: 4px;
  }
}

.caption {
  font-weight: @fontWeightBold;
  font-size: 12px;
  line-height: 15px;
  color: @gray4;
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.1em;

  strong {
    color: @gray5;
  }
}

.buttonWrap {
  display: flex;
  align-items: center;
  margin-bottom: 68px;
}

.buttonBox {
  flex-grow: 1;
  max-width: 200px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 1;
  margin-left: 10px;
  font-size: 20px;
  color: @gray4;
  cursor: pointer;
  border-radius: 8px;
  transition: @transition;

  &:hover {
    color: @gray5;
    background-color: @gray2;
  }
}

.infoList {
  margin-top: auto;

  &Item {
    display: flex;
    padding: 12px 0;
    border-top: 1px solid @gray3;
    font-weight: @fontWeightSemiBold;
    line-height: 16px;

    &:last-child {
      border-bottom: 1px solid @gray3;
    }

    &.infoListItemHot {
      color: @red1;
      align-items: center;
      border-top: 0;

      .infoListItemIcon {
        color: inherit;
      }
    }

    &Icon {
      flex-shrink: 0;
      font-size: 28px;
      color: @gray4;
      padding-right: 13px;
    }

    &Label {
      color: @gray4;
      font-size: 12px;
      font-weight: @fontWeightBold;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
}

.title {
  &:global(.h5) {
    margin-bottom: 4px;
  }
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: @fontWeightSemiBold;
  color: @gray5;
  margin-bottom: 8px;
}

.description {
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 5px;

  strong {
    font-weight: @fontWeightSemiBold;
  }

  a {
    display: inline-block;
    font-weight: @fontWeightSemiBold;
    line-height: 1;
    border-bottom: 1px solid @gray3;
    transition: @transition;

    &:hover {
      border-color: @primary-color;
      color: @primary-color;
    }
  }

  ul {
    margin-left: 28px;

    li {
      list-style: disc;
    }
  }
}

.descriptionList {
  padding-top: 10px;
  display: flex;
  margin: 0 -20px;

  li {
    padding: 0 20px;
    color: @gray4;
    font-size: 14px;
    font-weight: @fontWeightSemiBold;
  }

  span {
    font-weight: @fontWeightMedium;
    color: @main-text;
  }

  i {
    font-size: 24px;
    vertical-align: bottom;
  }
}

.formWrap {
  max-width: 454px;
  margin-bottom: 32px;
}

.indent {
  margin-bottom: 16px;
}

.indentXs {
  margin-bottom: 8px;
}

.alert {
  border-radius: 8px;
  padding: 16px;
  display: flex;
  background-color: rgba(@blue3, 0.1);

  &Icon {
    font-size: 18px;
    padding-right: 8px;
    line-height: 27px;
    flex-shrink: 0;
    color: @gray4;
  }

  &Text {
    font-size: 18px;
    line-height: 27px;
    font-weight: @fontWeightBold;
    color: @gray5;
  }

  &.alertGray {
    background-color: @gray2;

    .alertText {
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }
}
