.drop {
  padding: 16px 8px;
  background-color: rgba(@white, 0.95);
  border: 1px solid @gray3;
  box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 8px;

  &-item {
    display: flex;
    align-items: center;
    font-weight: @fontWeightMedium;
    font-size: 14px;
    line-height: 26px;
    color: @main-text;
    padding: 5px 16px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;

    &.active {
      color: @gray4;
    }

    &:hover {
      background-color: @primary-color;
      color: @white;
      transition: all 0.3s ease;

      .drop-item__icon {
        color: inherit;
      }
    }

    &__icon {
      font-size: 18px;
      min-width: 18px;
      margin-right: 8px;
      color: @gray4;
    }
  }
}

body {
  .ant-dropdown-menu {
    padding: 16px 8px;
    background-color: rgba(@white, 0.95);
    border: 1px solid @gray3;
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
    backdrop-filter: blur(10px);
  }

  .ant-dropdown-menu-item {
    padding: 5px 16px;
    border-radius: 8px;
    font-weight: @fontWeightMedium;
    font-size: 14px;
    line-height: 26px;
    color: @main-text;

    &.ant-dropdown-menu-item-active,
    &:hover {
      color: @white;
      background-color: @primary-color;
    }
  }

  .ant-dropdown-menu-item-selected {
    color: @gray4;
    background-color: transparent;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0 16px;
    background-color: @gray2;
  }

  .ant-btn-link {
    &.ant-dropdown-open {
      color: @gray4;
      background-color: @gray2;
    }
  }

  .ant-select-dropdown {
    background-color: rgba(@white, 0.95);
    border: 1px solid @gray3;
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
    backdrop-filter: blur(10px);
  }
}
