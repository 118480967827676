@import 'styles/base/_variables.less';

.status {
  display: inline-block;
  border-radius: 6px;
  padding: 4px 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06em;
  font-weight: @fontWeightBold;
  white-space: nowrap;
}

.active,
.comingSoon {
  background-color: rgba(@green2, 0.1);
  color: @green2;
}

.draft,
.ended,
.withdrawn {
  background-color: @gray2;
  color: @gray4;
}

.sold {
  background-color: rgba(@blue7, 0.1);
  color: @blue7;
}

.pending {
  background-color: rgba(@yellow1, 0.2);
  color: @yellow1;
}
