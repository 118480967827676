@import 'styles/base/_variables.less';
@import 'styles/base/customize.less';

.wrap {
  position: relative;
  padding: 24px 0 48px;

  .upload {
    :global {
      .ant-upload-list-picture-card-container,
      .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: auto;
        position: relative;
        padding-bottom: 100%;

        > .ant-upload {
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  :global {
    .prefix-label {
      width: 120px;
    }
  }
}

.backBtn {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    margin-bottom: 0;
    position: absolute;
    top: 24px;
    left: 24px;
  }
}

.btn {
  width: 50%;
  margin-left: auto;
}

.formBox {
  padding-right: 28px;
  max-width: 530px;
}

.box {
  margin-bottom: 32px;
}

.popoverWrap {
  position: relative;

  .popoverOpener {
    position: absolute;
    left: calc(100% + 3px);
    top: 50%;
    transform: translateY(calc(-50% - @form-item-margin-bottom / 2));
  }
}

.popoverContainer {
  max-width: 360px;
}

.popoverOpener {
  display: inline-block;
  padding: 5px;
  line-height: 1;
  font-size: 20px;
  color: @gray4;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: @gray5;
    background-color: @gray2;
  }

  &.popoverOpenerSm {
    font-size: 16px;
    vertical-align: middle;
  }
}

.option {
  &:global(.ant-select-item) {
    padding: 8px 24px;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: @fontWeightSemiBold;
}

.subTitle {
  font-size: 12px;
  font-weight: @fontWeightMedium;
  color: @gray5;
  line-height: 17px;
}
