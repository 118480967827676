@import 'styles/base/_variables.less';

.name {
  font-weight: @fontWeightBold;
}

.nickname {
  color: @gray4;
}

.label {
  font-size: 10px;
  font-weight: @fontWeightSemiBold;
  letter-spacing: 0.08em;
  color: @gray5;
  text-transform: uppercase;
  line-height: 13px;
}

.number {
  font-size: 10px;
  font-weight: @fontWeightSemiBold;
  color: @gray4;
  letter-spacing: 0.04em;
  line-height: 14px;
}
