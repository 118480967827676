@import 'styles/base/_variables.less';

.img {
  width: 80px;
  margin-bottom: 16px;
}

.wrap {
  padding: 24px 0;

  p {
    margin-bottom: 24px;
  }

  :global {
    .h4 {
      margin-bottom: 8px;
      font-weight: @fontWeightSemiBold;
    }

    .ant-btn-primary {
      margin-right: 40px;
      min-width: 150px;
    }

    .paper {
      padding: 7vw;
    }
  }
}

.content {
  max-width: 564px;
}
