@import 'styles/base/_variables.less';

.bgWrap {
  padding-bottom: 30px;

  @media (min-width: @breakpoint-md) {
    padding-bottom: 100px;
  }

  :global {
    .ant-divider {
      @media (min-width: @breakpoint-lg) {
        margin: 32px 0 40px;
      }
    }

    .h2 {
      text-align: center;
      margin-bottom: 8px;
    }

    .h3 {
      margin-bottom: 16px;

      @media (min-width: @breakpoint-md) {
        font-size: 38px;
        margin-top: -16px;
        margin-bottom: 0;
      }
    }
  }

  .title {
    text-align: center;
    max-width: 910px;

    &:global(.h3) {
      margin: 0 auto 30px;
    }
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.heroSection {
  padding: 120px 0 48px;
  text-align: center;

  @media (min-width: @breakpoint-md) {
    padding-top: 220px;
  }

  h5 {
    color: @gray4;
  }
}

.generalSection {
  padding: 40px 0;

  @media (min-width: @breakpoint-md) {
    padding-top: 136px;
  }
}

.contactSection {
  padding-top: 15px;

  @media (min-width: @breakpoint-md) {
    padding-top: 50px;
  }
}
