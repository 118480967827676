@import 'styles/base/_variables.less';

.wrap {
  position: absolute;
  bottom: 16px;
  background-color: @blue1;
  color: @white;
  border-radius: 12px;
  padding: 16px 24px;
  max-width: 512px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  text-align: center;
  width: calc(100% - 20px);

  a {
    color: @white;
    font-weight: @fontWeightSemiBold;
    display: inline-block;
    border-bottom: 1px solid @gray6;
  }
}
