@import 'styles/base/_variables.less';

.titleBox {
  padding: 0 0 4px 16px;

  :global {
    .ant-btn {
      min-width: 160px;
    }
  }
}
