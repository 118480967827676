@import 'styles/base/_variables.less';

.wrapper {
  padding-top: 218px;
  background-image: url('../../../assets/images/mainbg.webp');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;
  flex-direction: column;

  :global {
    .ant-spin-nested-loading {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      > .ant-spin-container {
        flex-grow: 1;
      }
    }
  }
}

.block {
  margin: 0 auto 20px;
  max-width: 690px;
  text-align: center;

  :global {
    .ant-btn {
      min-width: 110px;
    }

    .ant-input-prefix {
      font-size: 18px;
      color: @gray4;
    }
  }
}

.title {
  &:global(.h2) {
    margin-bottom: 8px;
  }
}

.subtitle {
  color: @gray6;
  font-weight: @fontWeightSemiBold;
  font-size: 16px;

  @media (min-width: @breakpoint-lg) {
    font-size: 22px;
    margin-bottom: 24px;
    line-height: 28px;
  }
}

.container {
  &:global(.container) {
    padding-top: 60px;
    padding-bottom: 32px;
  }
}
