@import 'styles/base/_variables.less';

.nav {
  li {
    &:not(:last-child, .navHead) {
      margin-bottom: 6px;
    }
  }

  &Head {
    margin-bottom: 16px;

    :global {
      .ant-btn {
        justify-content: center;
      }
    }
  }
}

.navItem {
  display: flex;
  align-items: center;
  padding: 9px 8px;
  font-weight: @fontWeightSemiBold;
  color: @gray5;
  font-size: 14px;
  line-height: 20px;
  border-radius: @border-radius-base;
  transition: all 0.3s;

  i {
    font-size: 18px;
    padding-right: 8px;
  }

  &:hover {
    background-color: @gray2;
    color: @main-text;
    transition: all 0.3s;
  }

  &:global(.active) {
    color: @primary-color;
    background-color: rgba(@blue3, 0.1);
    pointer-events: none;

    &:hover {
      cursor: default;
    }
  }
}
