@import 'styles/base/_variables.less';

.bgWrap {
  background-image: url('../../../assets/images/mainbg.webp');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 64px;
  white-space: pre-wrap;

  a {
    display: inline-block;
    font-weight: @fontWeightSemiBold;
    line-height: 1;
    border-bottom: 1px solid @gray3;
    transition: @transition;

    &:hover {
      border-color: @primary-color;
      color: @primary-color;
    }
  }

  ul {
    margin-left: 28px;

    li {
      list-style: disc;
    }
  }
}

.whatSection {
  padding-top: 30px;

  :global {
    .ant-divider {
      @media (min-width: @breakpoint-lg) {
        margin: 32px 0;
      }
    }
  }
}

.imgBox {
  filter: drop-shadow(2px 2px 10px rgba(35, 51, 79, 0.1))
    drop-shadow(4px 4px 10px rgba(35, 51, 79, 0.1));
  overflow: hidden;
  border: 1px solid @gray2;
  border-radius: @border-radius-base;
  margin-bottom: 15px;

  @media (min-width: @breakpoint-lg) {
    margin-bottom: 32px;
  }
}

.textBox {
  @media (min-width: @breakpoint-lg) {
    padding-bottom: 32px;
  }
}

.faqSection {
  @media (min-width: @breakpoint-lg) {
    padding: 32px 0;
  }
}

.contactSection {
  padding-bottom: 20px;

  @media (min-width: @breakpoint-lg) {
    padding: 32px 0 117px;
  }
}
