@import 'styles/base/_variables.less';

.message {
  :global {
    .ant-message-notice-content {
      color: @white;
      font-weight: @fontWeightSemiBold;
      font-size: 14px;
      padding: 7px 10px;
    }

    .icon {
      font-size: 18px;
      margin-right: 4px;
      vertical-align: text-top;
    }
  }
}

.error {
  :global {
    .ant-message-notice-content {
      background-color: @red1;
    }
  }
}
.success {
  :global {
    .ant-message-notice-content {
      background-color: @primary-color;
    }
  }
}
