body {
  .form-label {
    font-size: 16px;
    font-weight: @fontWeightBold;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .ant-form-item-control-input-content {
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(35, 51, 79, 0.06),
      4px 4px 20px rgba(35, 51, 79, 0.06);
  }
  .clear-shadow {
    .ant-form-item-control-input-content {
      box-shadow: none;
    }
  }

  .ant-input-number-group-addon {
    font-weight: @fontWeightMedium;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-picker-focused,
  .ant-input-number-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused {
    border-color: @primary-color;
  }

  .ant-checkbox-inner,
  .ant-radio-inner {
    border-color: @gray4;
  }

  .ant-input-number-group-wrapper {
    display: block;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 50px;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 48px;
      }

      &:not(.ant-select-customize-input) {
        .ant-select-selection-search-input {
          height: 48px;
        }
      }
    }
  }

  .ant-select-arrow {
    font-size: 18px;
    color: @blue1;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
    font-weight: @fontWeightSemiBold;
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
  }

  .ant-picker-suffix {
    font-size: 20px;
  }

  // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  //   .ant-select-selector {
  //     border-color: ;
  //     box-shadow: ;
  //     color: ;
  //   }

  //   .ant-select-arrow {
  //     color: ;
  //   }
  // }

  // .ant-select-single.ant-select-open .ant-select-selection-item {
  //   color: ;
  // }

  // .ant-checkbox-wrapper {
  //   &:not(.ant-checkbox-wrapper-checked) {
  //     &:hover {
  //       .ant-checkbox-inner {
  //         border-color: ;
  //       }
  //     }

  //     .ant-checkbox:hover .ant-checkbox-inner {
  //       border-color: ;
  //     }
  //   }
  // }

  // .ant-radio-wrapper {
  //   &:not(.ant-radio-wrapper-checked) {
  //     &:hover {
  //       .ant-radio {
  //         border-color: ;
  //       }
  //     }

  //     .ant-radio:hover .ant-radio-inner {
  //       border-color: ;
  //     }
  //   }
  // }
}

.prefix-label {
  border: 1px solid @gray3;
  min-height: 40px;
  background-color: @white;
  padding: 5px 10px;
  font-weight: 600;
  white-space: nowrap;
  min-width: 0;

  &.prefix-label--lg {
    min-height: 50px;
    line-height: 38px;
  }
}

.ant-form-item-has-error {
  .prefix-label {
    border: 1px solid @red1;
  }
}

.input-group {
  &.ant-input-group {
    display: flex;

    &.ant-input-group-compact {
      .prefix-label {
        margin-right: 0;
        border-right: 0;
        flex: 0 0 auto;
      }
    }
  }

  .prefix-label {
    & + * {
      flex: 1 1 auto;
      min-width: 0;
    }
  }

  .ant-input-number-group-addon,
  .ant-input-group-addon {
    &:first-child {
      border-radius: 0;
    }
  }

  .ant-input-number {
    border-radius: 0px;
  }
}
