@import 'styles/base/_variables.less';

.bgWrap {
  background-image: url('../../assets/images/mainbg.webp');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 64px;
  white-space: pre-wrap;
}

.whatSection {
  padding-top: 30px;

  @media (min-width: @breakpoint-lg) {
    padding-bottom: 40px;
  }

  :global {
    .ant-divider {
      @media (min-width: @breakpoint-lg) {
        margin: 32px 0;
      }
    }
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .itsNutselGettingStarted {
    padding-top: 24px;
  }
}

.imgBox {
  filter: drop-shadow(2px 2px 10px rgba(35, 51, 79, 0.1))
    drop-shadow(4px 4px 10px rgba(35, 51, 79, 0.1));
  overflow: hidden;
  border: 1px solid @gray2;
  border-radius: @border-radius-base;
  margin-bottom: 15px;

  @media (min-width: @breakpoint-lg) {
    margin-bottom: 32px;
  }
}

.contactSection {
  padding-bottom: 20px;

  @media (min-width: @breakpoint-lg) {
    padding: 32px 0 117px;
  }
}
