@import 'styles/base/_variables.less';

.card {
  background-color: @white;
  box-shadow: 2px 2px 4px rgba(35, 51, 79, 0.12);
  border-radius: @border-radius-base;
  overflow: hidden;

  &.cardSold {
    .cardPrice {
      color: @gray5;
    }

    .cardTitle,
    .cardAddress {
      color: @gray4;
    }

    .cardInfo {
      li {
        color: @gray5;
      }
    }
  }

  &Img {
    position: relative;
    display: block;
    padding-bottom: 66%;
    background-color: @gray2;

    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;

      &:not(.empty) {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        transform: none;
      }
    }
  }

  &BadgeWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px;
    display: flex;
    justify-content: space-between;
  }

  &Offer {
    display: inline-block;
    border-radius: @border-radius-base;
    padding: 6px 8px;
    color: @white;
    font-weight: @fontWeightBold;
    font-size: 14px;
    line-height: 18px;
    background-color: @red2;

    i {
      font-size: 16px;
    }
  }

  &Content {
    padding: 8px 15px 15px;
  }

  &Head {
    display: flex;
    min-height: 40px;
    margin-bottom: 3px;

    &Left {
      display: flex;
      flex: 1 1 auto;
      min-width: 0px;
      padding-right: 5px;
    }

    &Right {
      flex: 0 0 auto;
      min-width: 0px;

      :global {
        .ant-btn-sm {
          min-width: 94px;
        }
      }
    }

    &Grow {
      flex: 1 1 auto;
      min-width: 0px;
    }

    &Info {
      font-size: 8px;
      font-weight: @fontWeightBold;
      line-height: 10px;
      color: @gray4;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &Price {
    font-size: 22px;
    font-weight: @fontWeightBold;
    line-height: 30px;
    color: @main-text;

    strong {
      color: @gray4;
      padding-right: 4px;
    }
  }

  &Title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    font-weight: @fontWeightSemiBold;
    line-height: 20px;
    color: @main-text;
    margin-bottom: 8px;
  }

  &Info {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 8px;

    li {
      padding: 0 8px;
      color: @main-text;
      font-size: 14px;
      font-weight: @fontWeightMedium;
      line-height: 20px;
    }

    i {
      font-size: 20px;
      color: @gray4;
      padding-right: 4px;
    }
  }

  &Address {
    display: flex;
    font-size: 13px;
    font-weight: @fontWeightMedium;
    line-height: 18px;
    color: @gray5;

    i {
      font-size: 18px;
      padding-right: 8px;
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
