@import 'styles/base/_variables.less';

.card {
  box-shadow: 0px 2px 4px rgba(35, 51, 79, 0.06),
    4px 4px 20px rgba(35, 51, 79, 0.06);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 16px;

  @media (min-width: @breakpoint-xl) {
    grid-template-columns: repeat(auto-fill, minmax(388px, 1fr));
  }
}

.counter {
  color: @gray4;
  font-size: 18px;
  font-weight: @fontWeightBold;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 18px;
  line-height: 27px;
  font-weight: @fontWeightSemiBold;
  color: @gray5;
}

.empty {
  text-align: center;
  margin-bottom: 50px;

  :global {
    .h5 {
      margin-bottom: 8px;
    }
  }
}
