@import 'styles/base/_variables.less';

.wrapper {
  padding-bottom: 40px;
}

.heroSection {
  position: relative;
}

.carouselSlide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  min-height: 400px;
  max-height: 700px;
}

.contentBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  min-height: 400px;
  max-height: 700px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global {
    .h2 {
      color: @white;
      margin-bottom: 32px;
    }
  }
}

.input {
  max-width: 658px;
  width: 100%;

  :global {
    .ant-btn {
      min-width: 110px;
    }

    .ant-input-prefix {
      color: @gray4;
    }
  }
}

.sellers {
  color: @white;
  padding-top: 32px;

  a {
    color: @white;
    text-decoration: underline;
  }
}

.sectionHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid @gray3;
  padding: 24px 0 8px;
  margin-bottom: 16px;

  :global {
    .h5 {
      margin-bottom: 0;
    }
  }
}
