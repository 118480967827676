@import 'styles/base/_variables.less';

.checkbox {
  padding-bottom: 24px;
  font-size: 12px;

  :global {
    .custom-link {
      font-size: 12px;
    }
  }
}

.caption {
  color: @gray5;
}
