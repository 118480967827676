@import 'styles/base/_variables.less';

.spinnerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  border-radius: 12px;
  background-color: @white;
  box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
  backdrop-filter: blur(10px);
}

.spinner {
  display: flex;
  align-items: flex-end;
  animation: loadingCircle 1s infinite linear;
  width: 40px;
  height: 40px;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
