@import 'styles/base/_variables.less';

.uploadHint,
.checkboxText {
  font-size: 12px;
  font-weight: @fontWeightMedium;
  line-height: 18px;
  margin-bottom: 8px;
  color: @gray5;
}

.info {
  font-size: 10px;
  font-weight: @fontWeightBold;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: @gray4;
  max-width: 340px;
  margin-bottom: 16px;
}

.checkboxText {
  margin-bottom: 0;
  padding-left: 24px;
}

.checkbox {
  padding-bottom: 24px;

  :global {
    .ant-form-item-explain-error {
      padding-left: 24px;
    }
  }
}
