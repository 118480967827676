@import 'styles/base/_variables.less';

.switchBox {
  line-height: 1;
}

.switchText {
  font-size: 12px;
  line-height: 17px;
  font-weight: @fontWeightMedium;
  max-width: 365px;
}

.switchGray {
  color: @gray6;
}
