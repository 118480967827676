@import 'styles/base/_variables.less';

.footer {
  &:global(.ant-layout-footer) {
    background-color: @gray2;
    padding: 15px 0;

    @media (min-width: @breakpoint-md) {
      padding: 24px 0 18px;
    }
  }
}

.footerNav {
  @media (min-width: @breakpoint-xs) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footerNavList {
  display: flex;
  margin: 0 -9px 10px;

  @media (min-width: @breakpoint-md) {
    margin-bottom: 22px;
  }

  li {
    padding: 0 9px;
  }
}

.footerLink {
  display: inline-flex;
  align-items: center;
  padding: 5px 6px;
  border-radius: 8px;
  cursor: pointer;
  color: @gray5;
  font-weight: @fontWeightSemiBold;
  font-size: 16px;
  transition: all 0.3s;

  &.footerLinkSm {
    font-size: 12px;
    font-weight: @fontWeightMedium;
  }

  &.footerContactLink {
    font-size: 14px;

    i {
      font-size: 18px;
      padding-right: 6px;
    }
  }

  &:global(.active) {
    pointer-events: none;
    color: @gray4;
  }

  &:hover,
  &:global(.ant-dropdown-open) {
    background-color: @gray3;
    color: @gray4;
    transition: all 0.3s;

    &:global(.drop-opener) {
      i {
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
  }
}

a.footerLink {
  white-space: nowrap;
}

.footerContact {
  border: 1px solid @gray3;
  border-width: 1px 0;
  padding: 10px 0 0;

  @media (min-width: @breakpoint-md) {
    padding: 26px 0 16px;
  }

  &List {
    @media (min-width: @breakpoint-md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -9px;
    }

    @media (min-width: @breakpoint-lg) {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }

  li {
    line-height: 1;
    margin-bottom: 10px;

    @media (min-width: @breakpoint-md) {
      padding: 0 9px;
    }
  }
}

.footerRow {
  display: flex;
  padding-top: 10px;

  @media (min-width: @breakpoint-md) {
    align-items: center;
    padding-top: 18px;
  }
}

.footerImg {
  flex-shrink: 0;
  opacity: 0.6;
  max-width: 90px;
}

.footerSubImg {
  flex-shrink: 0;
  font-size: 10px;
  line-height: 13px;
  font-weight: @fontWeightMedium;
  color: @gray5;

  img {
    max-height: 33px;
    width: auto;
    margin-bottom: 4px;

    @media (min-width: @breakpoint-sm) {
      margin-bottom: 0;
    }
  }
}

.footerPrefix {
  font-size: 8px;
  color: @gray5;
  padding: 0 3px;

  @media (min-width: @breakpoint-md) {
    align-self: center;
  }
}

.footerLicense {
  color: @gray5;
  font-size: 12px;
  line-height: 18px;
  padding-left: 10px;

  @media (min-width: @breakpoint-md) {
    padding-left: 30px;
  }

  strong {
    font-weight: @fontWeightSemiBold;
  }
}
