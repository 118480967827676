@import 'styles/base/_variables.less';

.wrap {
  padding: 80px 0 40px;
}

.btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.img {
  width: 50px;
  margin: 0 auto 16px;
}

.title {
  max-width: 250px;
  margin: 0 auto 16px;
  text-align: center;
}

.infoBox {
  padding: 0 40px;
}

.infoText {
  max-width: 370px;
}
