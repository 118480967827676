@import 'styles/base/_variables.less';

.wrap {
  user-select: none;
  touch-action: none;
  background-color: @gray1;
  border: 1px solid @gray4;
  border-radius: @border-radius-base;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.uploading {
    border-style: dashed;
  }

  &.error {
    border-color: @red1;

    .text {
      color: @red1;
    }
  }

  &.sortable:hover {
    cursor: move;
  }

  &.dragging {
    box-shadow: 4px 4px 8px rgba(35, 51, 79, 0.12);
    z-index: 1;
  }

  &:hover {
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

.overlay {
  border-radius: @border-radius-base - 1;
  background-color: rgba(@blue1, 0.9);
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: @transition;
}

.progressBar {
  width: 50px;
  margin: 0 auto;

  :global {
    .ant-progress-inner {
      background-color: @gray3;
    }
  }
}

.icon {
  font-size: 18px;
}

.text {
  font-size: 12px;
  line-height: 17px;
  color: @gray4;
  font-weight: @fontWeightMedium;
  text-align: center;
}

.button {
  &:global(.ant-btn) {
    color: @white;

    > * {
      font-size: 22px;
    }

    &[disabled] {
      color: @gray5;

      &:hover {
        color: @gray5;
      }
    }

    &:hover {
      color: @white;
    }
  }
}

.tooltip {
  &:global(.ant-tooltip) {
    padding: 0;

    :global {
      .ant-tooltip-arrow {
        display: none;
      }

      .ant-tooltip-inner {
        background-color: rgba(@blue1, 0.9);
      }
    }
  }
}

.img {
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: contain;
}
