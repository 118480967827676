@import 'styles/base/_variables.less';

.filter {
  display: flex;
  margin: 0 -8px;
  padding-left: 16px;

  li {
    padding: 0 4px;
  }
}

.filterItem {
  font-size: 14px;
  color: @primary-color;
  cursor: pointer;
  padding: 4px;
  border-radius: @border-radius-base;
  transition: @transition;

  &:hover {
    &:not(.active, .disabled) {
      background-color: rgba(@primary-color, 0.1);
    }
  }

  &.active {
    font-weight: @fontWeightBold;
    color: @gray5;
    cursor: default;
  }

  &.disabled {
    color: @gray4;
    cursor: default;
  }
}

.number {
  font-weight: @fontWeightMedium;
  color: @gray4;
}
