@import 'styles/base/_variables.less';

.content {
  line-height: 1.7;
}

.description {
  margin-bottom: 8px;
  color: @gray6;
}
