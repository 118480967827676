@import 'styles/base/_variables.less';

.checkboxLabel {
  font-size: 12px;
}

.checkboxText {
  &:global(.modal__subtitle) {
    font-weight: 400;
    margin-bottom: 0;

    :global {
      .ant-btn {
        font-weight: @fontWeightMedium;
      }
    }
  }
}
