@import 'styles/base/_variables.less';

.wrap {
  position: relative;
  padding: 24px 0 48px;
}

.backBtn {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    margin-bottom: 0;
    position: absolute;
    top: 24px;
    left: 24px;
  }
}
