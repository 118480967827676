@import 'styles/base/_variables.less';

.contactHead {
  display: flex;
  margin-bottom: 16px;

  &Img {
    width: 76px;
    height: 76px;
    flex-shrink: 0;
    border: 1px solid @gray2;
    border-radius: @border-radius-base;
    overflow: hidden;
  }

  &Info {
    padding-left: 12px;
    flex-grow: 1;
  }

  &Name {
    font-weight: @fontWeightBold;
    font-size: 18px;
    line-height: 27px;
    color: @blue1;
  }

  &Company {
    font-weight: @fontWeightBold;
    color: @red1;
    font-size: 14px;
    line-height: 21px;
  }

  &License {
    font-size: 10px;
    color: @gray4;
    font-weight: @fontWeightMedium;
    line-height: 15px;
  }
}

.contactTitle {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: @fontWeightBold;
  line-height: 12px;
  color: @gray4;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
}

.contactList {
  li {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  a {
    font-size: 12px;
    font-weight: @fontWeightSemiBold;
    line-height: 16px;
  }

  i {
    font-size: 18px;
    vertical-align: text-bottom;
    padding-right: 5px;
  }
}
