@import 'styles/base/_variables.less';

.wrap {
  padding: 88px 0 50px;
}

.card {
  max-width: 668px;
  padding: 8px;
}

.spinWrap {
  text-align: center;
  margin-top: 20%;
}

.backBtn {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    margin-bottom: 0;
    position: absolute;
    top: 88px;
    left: 24px;
  }
}

.tabs {
  :global {
    .ant-table-wrapper {
      margin-bottom: 40px;
    }

    .ant-empty {
      border-radius: @border-radius-base;
      background-color: @gray1;
      padding: 16vh 20px 25vh;
      margin: 0;
    }
  }

  &:global(.ant-tabs-top) {
    :global {
      .ant-tabs-nav {
        padding: 0 8px;

        &:before {
          left: -16px;
          right: -16px;
        }
      }
    }
  }
}
