@import 'styles/base/_variables.less';

.badge {
  display: inline-block;
  padding: 6px 8px;
  border-radius: @border-radius-base;
  font-weight: @fontWeightBold;
  color: @gray5;

  &.small {
    background-color: rgba(@white, 0.9);
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
    backdrop-filter: blur(10px);
    font-size: 14px;
    line-height: 18px;

    i {
      font-size: 16px;
    }

    &.endsSoon {
      color: @red1;
    }
  }

  &.large {
    background-color: @gray2;
    font-size: 18px;
    line-height: 23px;

    i {
      font-size: 20px;
    }

    &.endsSoon {
      background-color: @red1;
      color: @gray1;
    }
  }
}
