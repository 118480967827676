@import 'styles/base/_variables.less';

.bgWrap {
  background-image: url('../../assets/images/mainbg.webp');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 120px 0 56px;

  @media (min-width: @breakpoint-xl) {
    padding-top: 218px;
  }
}

.titleBox {
  text-align: center;
  margin-bottom: 58px;

  :global {
    .h2 {
      margin-bottom: 0;
    }

    .h5 {
      font-weight: @fontWeightSemiBold;
      margin-bottom: 0;
      color: @gray6;
    }
  }
}

.collapse {
  :global {
    .ant-collapse-content-box {
      max-width: 778px;
    }
  }
}

.tabs {
  &:global(.ant-tabs-top) {
    :global {
      .ant-tabs-nav {
        &:before {
          width: 150vw;
          left: 50%;
          right: auto;
          transform: translateX(-50%);
        }
      }
    }
  }

  .collapse {
    &:global(.ant-collapse-borderless) {
      border: 0;
    }
  }
}

.contactSection {
  padding-top: 32px;
}
