@import 'styles/base/_variables.less';

.wrapper {
  padding: 24px;
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
  right: 16px;
  border-radius: 12px;
  background-color: @white;
  border: 1px solid @gray2;
  box-shadow: 0px 2px 4px rgba(35, 51, 79, 0.06),
    4px 4px 20px rgba(35, 51, 79, 0.06);

  @media (min-width: @breakpoint-md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :global {
    .ant-btn {
      min-width: 100px;

      @media (min-width: @breakpoint-xs) {
        min-width: 150px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

.title {
  font-size: 22px;
  font-weight: @fontWeightBold;
  line-height: 28px;
  margin-bottom: 0;
}

.text {
  margin-bottom: 0;
  font-weight: @fontWeightMedium;
}

.btn {
  display: flex;
  padding-top: 10px;

  @media (min-width: @breakpoint-md) {
    padding-top: 0;
  }
}
