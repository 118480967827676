@import 'styles/base/_variables.less';

.upload {
  :global {
    .with-icon {
      min-width: 120px;
      justify-content: center;
    }

    .ant-upload-text-icon {
      font-size: 24px;
    }

    .ant-upload-list-item {
      height: 56px;
      border: 1px solid @gray2;
      background-color: @gray1;
      border-radius: 8px;
      font-weight: @fontWeightMedium;

      &:not(.ant-upload-list-item-error) {
        color: @primary-color;
      }

      .ant-upload-list-item-card-actions-btn {
        opacity: 1;
        color: @gray4;
        height: 34px;

        > * {
          font-size: 18px;
        }
      }
    }

    .ant-upload-list-item-info {
      padding: 0 16px;
    }

    .ant-progress-inner {
      background-color: transparent;
    }

    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-select-picture-card {
      width: 114px;
      height: 114px;
      position: relative;
    }
  }
}

.modalImg {
  grid-row: body-row;
  display: flex;
  border-radius: @border-radius-base;
  border: 1px solid @gray3;
  padding: 8px;
  position: relative;

  img {
    object-fit: contain;
  }
}

.modalBtn {
  top: 16px;
  right: 16px;

  &:global(.ant-btn) {
    position: absolute;
  }
}
