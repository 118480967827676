@import 'styles/base/_variables.less';

.gridContainer {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) auto;
}

.wrap {
  text-align: center;
  padding: 80px 0 50px;
}

.title {
  &:global(.h4) {
    margin-bottom: 4px;
  }
}

.text {
  color: @gray5;
  font-size: 22px;
  line-height: 33px;
  font-weight: @fontWeightMedium;
  max-width: 384px;
  margin: 0 auto 40px;
}

.spinnerWrap {
  margin-bottom: 24px;
}

.spinner {
  animation: loadingCircle 1s infinite linear;
  width: 40px;
  height: 40px;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
