@import 'styles/base/_variables.less';

.slider {
  filter: drop-shadow(2px 2px 10px rgba(35, 51, 79, 0.1))
    drop-shadow(4px 4px 10px rgba(35, 51, 79, 0.1));
  margin-bottom: 20px;
  border: 1px solid rgba(@white, 0.5);
  overflow: hidden;
  border-radius: @border-radius-base;
}

.slide {
  padding-bottom: 57%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
