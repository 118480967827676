body {
  .ant-table-thead > tr > th {
    font-weight: @fontWeightBold;

    &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: @gray3;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom-width: 2px;
    font-weight: 500;
  }
}
