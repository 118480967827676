@import 'styles/base/_variables.less';

.modal {
  &:global(.ant-modal) {
    @media (min-width: @breakpoint-sm) {
      min-width: 550px;
      max-width: 670px;
    }
  }
}

.step {
  display: flex;
  flex-wrap: wrap;

  &:global(.modal__title) {
    margin-bottom: 16px;
  }

  i {
    font-size: 26px;
    color: @gray4;
    vertical-align: text-bottom;
    display: inline-block;
    padding: 0 6px;
  }

  &Item {
    color: @gray4;
    font-weight: @fontWeightMedium;

    &.activeStep {
      font-weight: @fontWeightBold;
      color: @main-text;
    }
  }
}

.container {
  @media (min-width: @breakpoint-sm) {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
  }

  :global {
    @media (min-width: @breakpoint-sm) {
      .ant-form {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: minmax(0, 1fr) auto;
      }
    }

    .modal__content {
      max-width: 550px;
      margin: 0 auto;
    }
  }

  > div {
    &:global(.ant-spin-spinning) {
      width: 100%;
      padding: 30px;
    }
  }
}
