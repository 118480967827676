@import 'styles/base/_variables.less';

.wrap {
  :global {
    .ant-empty-description {
      font-size: 22px;
      font-weight: @fontWeightSemiBold;
      line-height: 33px;
      color: @gray4;
    }
  }
}
