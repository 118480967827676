@import 'styles/base/_variables.less';

.emptyMessage {
  padding: 112px 0;
  text-align: center;

  :global {
    .h5 {
      margin-bottom: 8px;
    }
  }
}

.subtitle {
  font-size: 18px;
  line-height: 27px;
  font-weight: @fontWeightSemiBold;
  color: @gray5;
}
