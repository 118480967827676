body {
  .ant-collapse-borderless {
    background-color: transparent;
    border: solid @gray2;
    border-width: 1px 0;
    border-radius: 0;

    > .ant-collapse-item {
      border-color: @gray2;

      .ant-collapse-header {
        padding-left: 0;
        font-weight: @fontWeightSemiBold;
      }

      .ant-collapse-content {
        > .ant-collapse-content-box {
          padding-left: 24px;
        }
      }
    }
  }
}
