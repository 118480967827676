@import 'styles/base/_variables.less';

.notification {
  &:global(.ant-notification-notice) {
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
    backdrop-filter: blur(5px);
    background-color: rgba(@white, 0.95);
    padding: 16px;
    max-width: calc(100vw - 8px * 2);

    @media (min-width: @breakpoint-md) {
      width: 100%;
      max-width: 518px;
      padding: 24px;
    }
  }

  :global {
    .ant-notification-notice-close {
      display: inline-block;
      right: 16px;
      background-color: @gray2;
      color: @gray5;
      box-shadow: none;
      text-shadow: none;
      font-size: 20px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 8px;

      &:hover,
      &:focus {
        background-color: @gray3;
        color: @blue1;
      }
    }

    .ant-notification-notice-message {
      margin-bottom: 8px;

      h3 {
        font-size: 22px;
        line-height: 33px;
        font-weight: @fontWeightBold;
      }
    }

    .btn-secondary {
      min-width: 160px;
    }
  }
}

.accent {
  font-weight: @fontWeightSemiBold;
  padding-top: 8px;

  @media (min-width: @breakpoint-md) {
    padding-top: 16px;
  }
}

.btnBox {
  text-align: right;
  padding-top: 16px;

  @media (min-width: @breakpoint-md) {
    padding-top: 24px;
  }
}

.errorMessage {
  margin-top: 76px;

  @media (min-width: @breakpoint-sm) {
    margin-top: 64px;
  }
}
