@import 'styles/base/_variables.less';

.collapse {
  padding: 0;

  :global {
    .ant-collapse {
      border: 0;
      > .ant-collapse-item {
        > .ant-collapse-header {
          padding: 0 0 10px;
          font-size: 12px;
          color: @primary-color;
          line-height: 1.4;
        }

        .ant-collapse-content {
          > .ant-collapse-content-box {
            padding-top: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

.description {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.4;
  font-weight: @fontWeightSemiBold;
}

.player {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.list {
  padding: 0 0 16px 20px;
  border-bottom: 1px solid @gray2;
  color: @gray6;

  li {
    list-style: decimal;
  }
}

.link {
  font-weight: @fontWeightBold;
}
