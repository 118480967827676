body {
  font-family: @main-font;

  .ant-message {
    top: 0;
    position: absolute;
  }

  .ant-spin-blur::after {
    opacity: 0.6;
  }

  .ant-spin-nested-loading {
    min-height: 90px;
  }

  .ant-image-preview-img {
    width: auto;
  }

  .ant-image-mask {
    background-color: rgba(@blue1, 0.15);
  }

  .ant-popover {
    padding: 15px;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 12px;
      border: 1px solid @gray2;
      box-shadow: 0px 2px 4px rgba(35, 51, 79, 0.06),
        4px 4px 20px rgba(35, 51, 79, 0.06);
    }

    .ant-popover-inner-content {
      padding: 24px;
    }

    &.popover-lg {
      .ant-popover-inner-content {
        padding: 32px;
      }
    }
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    border-radius: 5px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    bottom: 1px;
    border-bottom: 2px solid @gray3;
  }

  .ant-tabs-tab-btn {
    font-weight: @fontWeightSemiBold;
  }
}

#root {
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

.ant-layout {
  position: relative;
  min-height: 100%;
}

.ant-layout-content {
  display: flex;

  > * {
    &:not(.auto-width) {
      width: 100%;
    }
  }
}

.layout-wrap {
  padding-top: 64px;
}

.d-flex {
  display: flex;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 16px;

  @media (min-width: @breakpoint-xxl) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1360px;
  padding: 0 15px;
}

.paper {
  padding: 16px;
  background-color: @white;
  border: 1px solid @gray2;
  border-radius: 8px;

  &.paper--lg {
    padding: 24px;
  }
}
