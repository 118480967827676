@import 'styles/base/_variables.less';

.header {
  &:global(.ant-layout-header) {
    background-color: @white;
    display: flex;
    align-items: center;
    z-index: 1;
    min-height: 76px;
    height: auto;
    line-height: inherit;
    padding: 5px 16px;
    box-shadow: 2px 2px 4px rgba(35, 51, 79, 0.12);
    border-bottom: 1px solid @gray3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: @breakpoint-sm) {
      padding: 5px 20px;
      min-height: 64px;
    }
  }

  &SubLogo {
    img {
      max-height: 33px;
      width: auto;
    }
  }

  &LogoText {
    font-size: 10px;
    line-height: 13px;
    font-weight: @fontWeightMedium;
    padding-top: 4px;
  }

  &Logo {
    display: block;
    max-width: 96px;

    @media (min-width: @breakpoint-md) {
      max-width: 90px;
    }
  }

  &Nav {
    display: flex;
    align-items: center;
    margin: 0 -4px;
    line-height: 1;

    @media (min-width: @breakpoint-md) {
      margin: 0 -6px;
    }

    li {
      padding: 0 4px;

      @media (min-width: @breakpoint-md) {
        padding: 0 6px;
      }
    }

    &Link {
      display: inline-flex;
      align-items: center;
      color: @main-text;
      transition: all 0.3s;
      font-size: 18px;
      line-height: 25px;
      font-weight: @fontWeightMedium;

      @media (min-width: @breakpoint-sm) {
        border-radius: 8px;
        cursor: pointer;
        letter-spacing: 0.14em;
        padding: 5px 6px;
        font-weight: @fontWeightBold;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.8;
      }

      &:global(.active) {
        color: @gray4;
        cursor: default;
        pointer-events: none;
      }

      i {
        @media (max-width: @breakpoint-sm - 1) {
          margin-right: 8px;
          color: @gray4;
          font-size: 20px;
        }
      }

      &:hover,
      &:global(.ant-dropdown-open) {
        color: @gray4;

        @media (min-width: @breakpoint-sm) {
          background-color: @gray2;
          transition: all 0.3s;
        }

        i {
          @media (min-width: @breakpoint-sm) {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }
        }
      }
    }
  }

  &Profile {
    display: inline-flex;
    align-items: center;
    padding: 5px 6px;
    border-radius: 8px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: @fontWeightBold;
    font-size: 12px;
    letter-spacing: 0.14em;
    color: @primary-color;
    transition: all 0.3s;

    i {
      padding-right: 6px;
      font-size: 18px;
      color: @gray4;
    }

    &:hover,
    &:global(.ant-dropdown-open) {
      background-color: @gray2;
      color: @gray4;
      transition: all 0.3s;
    }
  }

  &Login {
    &:global(.ant-btn) {
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: @fontWeightBold;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.14em;

      :global {
        .icon {
          font-size: 22px;

          @media (min-width: @breakpoint-sm) {
            font-size: 18px;
            padding-right: 4px;
          }
        }
      }
    }
  }

  &Logout {
    &:global(.drop-item) {
      color: @red1;

      i {
        color: inherit;
      }
    }
  }

  &.homeHeader {
    &:global(.ant-layout-header) {
      background-color: transparent;
      border-color: rgba(@gray2, 0.4);
    }
  }

  &Prefix {
    align-self: center;
    font-size: 8px;
    color: @gray5;
    padding: 0;
  }
}

.rightSection {
  margin-left: auto;
}

.homeHeader {
  .headerNav {
    .headerNavLink {
      color: @gray1;

      &:hover,
      &:global(.ant-dropdown-open) {
        background-color: rgba(@white, 0.1);
      }
    }
  }

  .headerProfile {
    color: @gray1;

    i {
      color: @gray1;
    }

    &:hover,
    &:global(.ant-dropdown-open) {
      background-color: rgba(@white, 0.1);
    }
  }

  .headerLogin {
    &:global(.ant-btn) {
      &:not(.custom-link) {
        color: @gray1;

        &:hover {
          background-color: rgba(@white, 0.1);
          color: @gray1;
        }
      }
    }
  }

  .headerPrefix {
    color: @gray1;
  }

  .headerLogoText {
    color: rgba(@white, 0.6);
  }

  .headerProfileMob {
    span {
      background-color: @white;
    }
  }
}

// mobile version

.headerProfileMob {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid transparent;

  span {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: @gray2;
    font-size: 14px;
    font-weight: @fontWeightBold;
    color: @primary-color;
    text-align: center;
    line-height: 30px;
  }

  &:global(.ant-dropdown-open) {
    border-color: rgba(@white, 0.4);
    transition: all 0.3s;
  }
}

.headerDropdownMob {
  &:global(.ant-dropdown) {
    padding: 0 8px;
    width: 100%;
  }
}

.headerMenuMob {
  &:global(.ant-drawer) {
    z-index: 1011;
  }
  :global {
    .ant-drawer-content {
      background-color: rgba(@gray1, 0.97);
    }
  }
}

.headerMenuClose {
  &:global(.ant-btn) {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 24px;
    line-height: 1;
    color: @gray4;
  }
}

.headerNavMob {
  li {
    margin-bottom: 24px;
    line-height: 1;
  }
}
