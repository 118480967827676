@import 'styles/base/_variables.less';

body {
  .quill {
    .ql-toolbar.ql-snow {
      border-color: @gray3;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-family: inherit;
      padding: 8px 16px;

      .ql-formats {
        margin-right: 8px;
      }

      button:hover {
        color: @primary-color;
      }
    }

    .ql-container.ql-snow {
      border-color: @gray3;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      font-family: inherit;
      min-height: 90px;
    }

    .ql-editor.ql-blank::before {
      color: @gray4;
      font-size: 14px;
      font-weight: @fontWeightMedium;
      font-style: normal;
    }
  }
}
