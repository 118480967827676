@import 'styles/base/_variables.less';

.currentBidRow {
  background-color: @gray1;
}

.voidedBidRow {
  opacity: 0.5;
}

.label {
  display: inline-block;
  border-radius: 6px;
  padding: 4px 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.06em;
  font-weight: @fontWeightBold;
  white-space: nowrap;
  color: @gray5;
  background-color: @gray2;
}

.actionsCell {
  &:before {
    display: none;
  }
}

.modal {
  &Btn {
    &:global(.ant-btn) {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  &Text {
    color: @gray5;
  }

  :global {
    .modal__title {
      padding-right: 46px;
    }
  }
}

.info {
  margin-bottom: 16px;
}

.infoText {
  font-weight: @fontWeightSemiBold;
  font-size: 14px;
  line-height: 1.7;
}
