body {
  .ant-modal-mask {
    background-color: rgba(@blue1, 0.2);
  }

  .ant-modal-content {
    position: relative;
    background-color: rgba(@white, 0.95);
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 12px;

    @media (min-width: @breakpoint-sm) {
      max-height: calc(100vh - 40px);
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(0, 1fr);
    }
  }

  .ant-modal-body {
    padding: 0;

    @media (min-width: @breakpoint-sm) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: [title-row] auto [body-row] minmax(0, 1fr);

      > .ant-spin-nested-loading {
        grid-row: body-row;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: minmax(0, 1fr);

        .ant-spin-container {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: auto minmax(0, 1fr);
        }
      }

      > .ant-form {
        grid-row: body-row;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: minmax(0, 1fr) auto;
      }
    }
  }
}

.modal {
  &__title {
    color: @blue1;
    font-size: 22px;
    font-weight: @fontWeightBold;
    line-height: 33px;
    margin-bottom: 8px;
    padding: 15px 15px 0;

    @media (min-width: @breakpoint-sm) {
      padding: 32px 40px 0;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    color: @gray5;
    font-size: 12px;
    font-weight: @fontWeightMedium;

    .custom-link {
      font-size: 12px;
    }
  }

  &__label {
    font-size: 12px;
    font-weight: @fontWeightBold;
    color: @gray5;
    line-height: 18px;
    margin-bottom: 8px;

    &.m-0 {
      margin-bottom: 0;
    }
  }

  &__message {
    margin-bottom: 16px;
  }

  &__divider {
    &.ant-divider {
      margin: 16px 0;
    }
  }

  &__scroll {
    overflow-y: auto;
  }

  &__content {
    padding: 0 15px;

    @media (min-width: @breakpoint-sm) {
      padding: 0 40px;
    }
  }

  &__footer {
    padding: 0 15px 15px;

    @media (min-width: @breakpoint-sm) {
      padding: 0 40px 32px;
    }

    &-row {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 32px;
      border-top: 1px solid @gray3;
    }

    .ant-btn {
      &:not(.custom-link) {
        min-width: 150px;
      }
    }
  }

  &__close {
    &.ant-btn {
      &.btn-icon {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 28px;
        height: 28px;
      }
    }
  }
}
