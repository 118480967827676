body {
  .ant-divider {
    border-top-width: 2px;

    &.divider-light {
      border-top-width: 1px;
      border-color: @gray3;
    }
  }
}
