.h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: @fontWeightBold;
  margin-bottom: 20px;

  @media (min-width: @breakpoint-md) {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 32px;
  }

  @media (min-width: @breakpoint-lg) {
    font-size: 50px;
    line-height: 63px;
  }

  &.m-0 {
    margin-bottom: 0;
  }

  &.alert {
    color: @red1;
  }

  span {
    font-weight: @fontWeightRegular;
  }
}

.h3 {
  font-weight: @fontWeightBold;
  font-size: 30px;
  line-height: 38px;

  @media (min-width: @breakpoint-md) {
    font-size: 42px;
    line-height: 52px;
  }
}

.h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: @fontWeightBold;

  @media (min-width: @breakpoint-md) {
    font-size: 30px;
    line-height: 38px;
  }

  span {
    font-weight: @fontWeightMedium;
  }
}

.h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: @fontWeightBold;
  margin-bottom: 16px;

  @media (min-width: @breakpoint-lg) {
    font-size: 22px;
    line-height: 28px;
  }
}

.default-list {
  margin-left: 28px;

  li {
    list-style: disc;
  }
}

.ws-nw {
  white-space: nowrap;
}
