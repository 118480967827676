@import 'styles/base/_variables.less';

.btn {
  &:global(.ant-btn.with-icon) {
    font-size: 12px;
    height: 22px;

    :global {
      .icon {
        font-size: 16px;
      }
    }
  }
}
