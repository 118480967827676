@import 'styles/base/_variables.less';

.modal {
  &:global(.ant-modal) {
    max-width: 100vw;
    margin: 0;
  }

  :global {
    .ant-modal-content {
      height: 100vh;
      max-height: 100vh;
      background-color: rgba(0, 0, 0, 0.45);
      -webkit-backdrop-filter: none;
      backdrop-filter: unset;
      border-radius: 0;
    }

    .ant-carousel {
      .slick-prev {
        left: 10px;
      }
      .slick-next {
        right: 10px;
      }

      .slick-arrow {
        color: @white;
        font-size: 18px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        line-height: 44px;
        z-index: 10;

        &:hover,
        &:focus {
          color: @white;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.slide {
  padding: 60px;
  height: calc(100vh - 40px);
}

.modalBtn {
  top: 16px;
  right: 16px;
  z-index: 1;
  position: absolute;
  color: @white;
  font-size: 30px;
  background-color: transparent;
  border: 0;
  line-height: 1;
  cursor: pointer;
  padding: 0;
}
