@import 'styles/base/_variables.less';

.wrap {
  padding-bottom: 32px;
}

.title {
  text-align: center;
  padding-top: 40px;

  &:global(.h4) {
    margin-bottom: 20px;

    @media (min-width: @breakpoint-md) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
    }
  }

  .contrast {
    color: @primary-color;
  }
}

.input {
  max-width: 690px;
  margin: 0 auto 4px;

  :global {
    .ant-btn {
      min-width: 125px;
    }

    .ant-input-prefix {
      color: @gray4;
    }
  }
}

.sortBox {
  text-align: right;
  margin-bottom: 6px;
}

.border {
  margin: 0 0 16px;
  border-bottom: 1px solid @gray3;
}
