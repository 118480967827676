@import 'styles/base/_variables.less';

.gallery {
  padding-top: 24px;

  :global {
    .ant-image {
      display: block;
      position: relative;
      padding-bottom: 66%;
    }

    .ant-image-img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
      border: 1px solid @gray2;
      box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.1),
        4px 4px 10px rgba(35, 51, 79, 0.1);
    }

    .ant-image-mask {
      border-radius: 8px;
    }
  }

  &Main {
    margin-bottom: 16px;
  }

  &Thumb {
    display: flex;
    margin: 0 -8px;

    &Box {
      width: 33.33%;
      padding: 0 8px;
    }

    .emptyImg {
      img {
        width: 20px;

        @media (min-width: @breakpoint-lg) {
          width: 32px;
        }
      }
    }
  }
}

.emptyImg {
  position: relative;
  padding-bottom: 66%;
  background-color: @gray2;
  border: 1px solid @gray3;
  border-radius: @border-radius-base;
  box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.1),
    4px 4px 10px rgba(35, 51, 79, 0.1);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;

    @media (min-width: @breakpoint-lg) {
      width: 100px;
    }
  }
}

.more {
  position: relative;
  padding-bottom: 66%;

  &Img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    border: 1px solid @gray2;
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.1),
      4px 4px 10px rgba(35, 51, 79, 0.1);
  }

  &Desc {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#23334f, 0.4);
    width: 100%;
    height: 100%;
    color: @white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    transition: all 0.3s;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      background-color: rgba(#23334f, 0.55);
    }

    strong {
      font-size: 26px;
      line-height: 33px;
    }
  }
}

.hidden {
  display: none;
}

.videoThumb {
  position: relative;
  padding-bottom: 66%;

  &:hover {
    cursor: pointer;
  }

  iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    border: 1px solid @gray2;
    box-shadow: 2px 2px 10px rgba(35, 51, 79, 0.1),
      4px 4px 10px rgba(35, 51, 79, 0.1);
  }
}
