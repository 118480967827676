@import 'styles/base/_variables.less';
@import 'styles/base/customize.less';

.wrap {
  position: relative;
  padding: 24px 0 48px;
}

.backBtn {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    margin-bottom: 0;
    position: absolute;
    top: 24px;
    left: 24px;
  }
}

.tabs {
  &:global(.ant-tabs-top) {
    :global {
      .ant-tabs-nav {
        &:before {
          left: -24px;
          right: -24px;
        }
      }
    }
  }
}

.form {
  :global {
    .prefix-label {
      min-width: 190px;
    }
  }
}

.formWrapXs {
  max-width: 428px;
}

.formWrapMd {
  max-width: 690px;
}

.formWrapLg {
  max-width: 784px;
}

.formWrapXl {
  max-width: 900px;
}

.uploadWrap {
  :global {
    .ant-form-item-control-input {
      & + div {
        > div {
          height: auto !important;
        }
      }
    }
  }
}

.uploadTitle {
  font-weight: @fontWeightBold;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
  max-width: 174px;

  &.uploadTitleFull {
    max-width: 100%;
  }
}

.uploadSubtitle {
  padding-top: 4px;
  font-weight: @fontWeightMedium;
  color: @gray6;
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: @fontWeightBold;
  padding-top: 16px;
  margin-bottom: 8px;

  &:first-child {
    padding-top: 0;
  }
}

.subTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: @fontWeightBold;
  margin-bottom: 8px;
  color: @gray5;
}

.btnWrap {
  padding-top: 8px;

  :global {
    .ant-btn {
      min-width: 160px;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}

.popoverWrap {
  position: relative;

  .popoverOpener {
    position: absolute;
    left: calc(100% + 3px);
    top: 50%;
    transform: translateY(calc(-50% - @form-item-margin-bottom / 2));
  }
}

.popoverContainer {
  max-width: 360px;
}

.popoverOpener {
  display: inline-block;
  padding: 5px;
  line-height: 1;
  font-size: 20px;
  color: @gray4;
  cursor: pointer;
  border-radius: 8px;
  transition: @transition;

  &:hover {
    color: @gray5;
    background-color: @gray2;
  }

  &.popoverOpenerSm {
    font-size: 16px;
    vertical-align: middle;
  }
}

.infoBox {
  display: inline-block;
  padding-top: 16px;
}
