@import 'styles/base/_variables.less';

.contactList {
  line-height: 16px;

  li {
    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  a {
    font-size: 12px;
    font-weight: @fontWeightSemiBold;
    color: @main-text;
  }

  i {
    font-size: 16px;
    vertical-align: text-bottom;
    padding-right: 6px;
  }
}

.unsubscribed {
  text-decoration: line-through;
}
