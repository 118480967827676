body {
  .ant-pagination {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        background-color: rgba(@blue3, 0.1);
        border: 0;
        color: @blue2;
        line-height: 1;

        &:hover,
        &:focus {
          background-color: @blue2;
          color: @white;
        }

        &[disabled] {
          background-color: @gray2;
          color: @blue1;
        }

        .icon {
          font-size: 18px;
        }
      }
    }

    .ant-pagination-item {
      background-color: rgba(@blue3, 0.1);
      border: 0;
      color: @blue2;
      font-size: 14px;
      font-weight: @fontWeightSemiBold;

      a {
        color: inherit;
        padding: 0 14px;
      }

      &.ant-pagination-item-active {
        background-color: @blue5;
        color: @white;
      }

      &:hover,
      &:focus {
        background-color: @blue2;
        color: @white;
      }
    }

    .ant-pagination-options-size-changer {
      &.ant-select:not(.ant-select-customize-input) {
        color: @blue2;

        &:hover {
          color: @white;
          .ant-select-selector {
            background-color: @blue2;
          }
        }

        .ant-select-selector {
          transition: all 0.3s ease;
          height: 32px;
          background-color: rgba(@blue3, 0.1);
          border: 0;

          .ant-select-selection-item {
            line-height: 32px;
            transition: none;
          }
        }
      }

      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
        color: @white;
        .ant-select-selector {
          box-shadow: none;
          background-color: @blue2;
        }
      }

      &.ant-select-single.ant-select-open .ant-select-selection-item {
        color: @white;
      }
    }

    .ant-select-arrow {
      color: inherit;
      transition: all 0.3s ease;

      > span {
        display: none;
      }

      &:after {
        content: '\e90f';
        font-family: '@{icomoon-font-family}';
        font-size: 18px;
      }
    }
  }

  .ant-pagination-options,
  .ant-pagination-prev {
    @media (min-width: @breakpoint-md) {
      margin-left: auto;
    }
  }
}
