@import 'styles/base/_variables.less';

.label {
  font-weight: @fontWeightMedium;
  line-height: 18px;

  div {
    font-size: 12px;
    line-height: 15px;
    color: @gray4;
    padding-top: 4px;
  }
}

.switchBox {
  line-height: 1;
}
