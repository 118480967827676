@import 'variables';

@font-face {
  font-family: '@{icomoon-font-family}';
  src: url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?31xq8')
      format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?31xq8') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?31xq8#@{icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download {
  &:before {
    content: @icon-download;
  }
}
.icon-brokerage {
  &:before {
    content: @icon-brokerage;
  }
}
.icon-edit {
  &:before {
    content: @icon-edit;
  }
}
.icon-link-broken {
  &:before {
    content: @icon-link-broken;
  }
}
.icon-dashboard {
  &:before {
    content: @icon-dashboard;
  }
}
.icon-arrow-up-right {
  &:before {
    content: @icon-arrow-up-right;
  }
}
.icon-shield-cross {
  &:before {
    content: @icon-shield-cross;
  }
}
.icon-check-square {
  &:before {
    content: @icon-check-square;
  }
}
.icon-fill-arrow-left {
  &:before {
    content: @icon-fill-arrow-left;
  }
}
.icon-reload {
  &:before {
    content: @icon-reload;
  }
}
.icon-folder {
  &:before {
    content: @icon-folder;
  }
}
.icon-logout {
  &:before {
    content: @icon-logout;
  }
}
.icon-coffee {
  &:before {
    content: @icon-coffee;
  }
}
.icon-heart {
  &:before {
    content: @icon-heart;
  }
}
.icon-circle-question {
  &:before {
    content: @icon-circle-question;
  }
}
.icon-sale {
  &:before {
    content: @icon-sale;
  }
}
.icon-bathrooms {
  &:before {
    content: @icon-bathrooms;
  }
}
.icon-beds {
  &:before {
    content: @icon-beds;
  }
}
.icon-garage {
  &:before {
    content: @icon-garage;
  }
}
.icon-size {
  &:before {
    content: @icon-size;
  }
}
.icon-angle-left {
  &:before {
    content: @icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: @icon-angle-right;
  }
}
.icon-angle-up {
  &:before {
    content: @icon-angle-up;
  }
}
.icon-arrow-left {
  &:before {
    content: @icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: @icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: @icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: @icon-arrow-down;
  }
}
.icon-caret-up {
  &:before {
    content: @icon-caret-up;
  }
}
.icon-caret-down {
  &:before {
    content: @icon-caret-down;
  }
}
.icon-sorting {
  &:before {
    content: @icon-sorting;
  }
}
.icon-expand {
  &:before {
    content: @icon-expand;
  }
}
.icon-collapse {
  &:before {
    content: @icon-collapse;
  }
}
.icon-burger {
  &:before {
    content: @icon-burger;
  }
}
.icon-list {
  &:before {
    content: @icon-list;
  }
}
.icon-sorting-alt {
  &:before {
    content: @icon-sorting-alt;
  }
}
.icon-filter-alt {
  &:before {
    content: @icon-filter-alt;
  }
}
.icon-receipt {
  &:before {
    content: @icon-receipt;
  }
}
.icon-document {
  &:before {
    content: @icon-document;
  }
}
.icon-delete {
  &:before {
    content: @icon-delete;
  }
}
.icon-add {
  &:before {
    content: @icon-add;
  }
}
.icon-prop-document {
  &:before {
    content: @icon-prop-document;
  }
}
.icon-close-square {
  &:before {
    content: @icon-close-square;
  }
}
.icon-info {
  &:before {
    content: @icon-info;
  }
}
.icon-more {
  &:before {
    content: @icon-more;
  }
}
.icon-plus-square {
  &:before {
    content: @icon-plus-square;
  }
}
.icon-up-square {
  &:before {
    content: @icon-up-square;
  }
}
.icon-danger {
  &:before {
    content: @icon-danger;
  }
}
.icon-info-circle {
  &:before {
    content: @icon-info-circle;
  }
}
.icon-preview {
  &:before {
    content: @icon-preview;
  }
}
.icon-minus {
  &:before {
    content: @icon-minus;
  }
}
.icon-plus {
  &:before {
    content: @icon-plus;
  }
}
.icon-close {
  &:before {
    content: @icon-close;
  }
}
.icon-time-square {
  &:before {
    content: @icon-time-square;
  }
}
.icon-clock {
  &:before {
    content: @icon-clock;
  }
}
.icon-time {
  &:before {
    content: @icon-time;
  }
}
.icon-timer {
  &:before {
    content: @icon-timer;
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}
.icon-linkedin {
  &:before {
    content: @icon-linkedin;
  }
}
.icon-eye {
  &:before {
    content: @icon-eye;
  }
}
.icon-flag {
  &:before {
    content: @icon-flag;
  }
}
.icon-map {
  &:before {
    content: @icon-map;
  }
}
.icon-login {
  &:before {
    content: @icon-login;
  }
}
.icon-mail {
  &:before {
    content: @icon-mail;
  }
}
.icon-home {
  &:before {
    content: @icon-home;
  }
}
.icon-filter {
  &:before {
    content: @icon-filter;
  }
}
.icon-location {
  &:before {
    content: @icon-location;
  }
}
.icon-bids {
  &:before {
    content: @icon-bids;
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar;
  }
}
.icon-phone {
  &:before {
    content: @icon-phone;
  }
}
.icon-category {
  &:before {
    content: @icon-category;
  }
}
.icon-fire-filled {
  &:before {
    content: @icon-fire-filled;
  }
}
.icon-fire {
  &:before {
    content: @icon-fire;
  }
}
.icon-search-error {
  &:before {
    content: @icon-search-error;
  }
}
.icon-search {
  &:before {
    content: @icon-search;
  }
}
.icon-settings {
  &:before {
    content: @icon-settings;
  }
}
.icon-shield {
  &:before {
    content: @icon-shield;
  }
}
.icon-stop {
  &:before {
    content: @icon-stop;
  }
}
.icon-trash {
  &:before {
    content: @icon-trash;
  }
}
.icon-upload {
  &:before {
    content: @icon-upload;
  }
}
.icon-user {
  &:before {
    content: @icon-user;
  }
}
.icon-users {
  &:before {
    content: @icon-users;
  }
}
