@import 'styles/base/_variables.less';

.wrap {
  position: relative;
  padding: 24px 0 48px;

  :global {
    .paper {
      margin-bottom: 16px;
    }

    .btn-group {
      > div {
        width: 50%;
      }
    }
  }
}

.backBtn {
  margin-bottom: 20px;

  @media (min-width: @breakpoint-xl) {
    margin-bottom: 0;
    position: absolute;
    top: 24px;
    left: 24px;
  }
}

.formIndent {
  @media (min-width: @breakpoint-lg) {
    padding-right: 45px;
  }

  :global {
    .prefix-label {
      width: 49%;
    }
  }
}

.label {
  &:global(.form-label) {
    margin-bottom: 16px;
  }
}

.uploaderWrap {
  font-size: 12px;
  line-height: 17px;
  font-weight: @fontWeightMedium;
  color: @gray5;

  i {
    font-size: 18px;
  }
}

.uploadTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: @fontWeightSemiBold;

  + .uploadBox {
    margin-top: 8px;
  }
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 10px;
}

.hint {
  font-size: 12px;
  line-height: 18px;
  font-weight: @fontWeightBold;
  color: @gray5;
  margin-bottom: 8px;

  div {
    color: @gray4;
    font-weight: @fontWeightMedium;
  }
}
