.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.brokerage {
  max-width: 350px;
}

.email {
  max-width: 240px;
}
