@import 'styles/base/_variables.less';

.message {
  display: flex;
  border-radius: 12px;
  padding: 40px 40px 40px 30px;
  background-color: rgba(@blue3, 0.1);

  &.messageGrey {
    background-color: @gray2;

    .messageText,
    .messageTitle {
      color: @gray5;
    }

    .messageIcon {
      color: @gray4;
    }
  }

  &.messageSmall {
    border-radius: 8px;
    padding: 16px;

    .messageText {
      font-size: 12px;
      line-height: 22px;
    }
  }

  &Icon {
    font-size: 22px;
    padding-right: 8px;
    line-height: 1;
    flex-shrink: 0;
    color: @blue2;
  }

  &Content {
    flex-grow: 1;
  }

  &Title {
    color: @blue2;
    font-weight: @fontWeightSemiBold;
    margin-bottom: 8px;

    &:only-child {
      margin-bottom: 0;
    }
  }

  &Text {
    font-size: 14px;
    line-height: 25px;
  }
}
