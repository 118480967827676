@import 'styles/base/_variables.less';

.container {
  max-width: 540px;
}

.textBox {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 5px;

  strong {
    font-weight: @fontWeightSemiBold;
  }
}

.message {
  font-weight: @fontWeightSemiBold;
  font-size: 14px;
}
