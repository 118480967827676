@import 'styles/base/_variables.less';

.content {
  line-height: 1.7;
}

.description {
  margin-bottom: 8px;
  color: @gray6;
}

.info {
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: @fontWeightSemiBold;
}

.price {
  font-size: 30px;
  line-height: 1.4;
  font-weight: @fontWeightBold;

  strong {
    color: @gray4;
    margin-right: 4px;
  }
}

.priceLabel {
  font-size: 12px;
  font-weight: @fontWeightBold;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: @gray4;
  margin-bottom: 8px;
}

.btnBox {
  margin: 0 0 16px -7px;
}

.formBox {
  margin-bottom: 16px;
}
