@import 'styles/base/_variables.less';

.table {
  :global {
    .ant-table-thead > tr > th {
      white-space: nowrap;
    }

    .ant-table-tbody > tr > td {
      &:local(.noSeparate) {
        padding-right: 0;
      }

      &:local(.last) {
        padding-left: 0;
      }
    }
  }
}

.address {
  width: 254px;

  &Link {
    display: inline-block;
    padding: 3px 7px;
    font-weight: @fontWeightSemiBold;
    transition: all 0.3s;
    border-radius: @border-radius-base;
    margin-left: -7px;

    &:hover {
      background-color: rgba(@blue3, 0.1);
      color: @blue2;
    }
  }
}

.agent {
  width: 130px;
}

.brokerage {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.views {
  i {
    color: @gray4;
    padding-right: 4px;
  }
}

.dash {
  color: @gray4;
}

.noSeparate {
  &:before {
    display: none;
  }
}
