.btn-group {
  display: flex;
  margin: -5px;

  > div {
    padding: 5px;
  }
}

body {
  .ant-btn {
    font-size: 14px;

    &:not(.ant-btn-link) {
      &.ant-btn-primary {
        &:hover,
        &:focus {
          background-color: @blue4;
          border-color: @blue4;
          box-shadow: 0px 1px 1px rgba(9, 9, 43, 0.3),
            0px 5px 14px rgba(97, 95, 255, 0.5);
        }

        &:active {
          background-color: @blue5;
          border-color: @blue5;
          box-shadow: none;
        }

        // &.load{
        //   background-color: @blue6;
        //   border-color: @blue6;
        //   box-shadow: none;
        //   color: rgba(@white, 0.6);
        // }

        &[disabled] {
          background-color: @gray3;
          border-color: @gray3;
          box-shadow: none;
          color: @gray4;
        }
      }

      &.btn-secondary {
        background-color: rgba(@blue3, 0.1);
        border: 0;
        color: @blue2;
        box-shadow: none;
        text-shadow: none;

        &:hover,
        &:focus {
          background-color: @blue2;
          border: 0;
          color: @white;
          box-shadow: 0px 1px 1px rgba(9, 9, 43, 0.2),
            0px 5px 10px rgba(97, 95, 255, 0.3);
          text-shadow: 0px 2px 2px rgba(35, 51, 79, 0.1);
        }

        &:active {
          background-color: @blue5;
          border-color: @blue5;
          box-shadow: none;
        }

        &[disabled] {
          background-color: @gray3;
          border-color: @gray3;
          box-shadow: none;
          color: @gray4;
          text-shadow: none;
        }
      }

      &.btn-success {
        background-color: @green1;
        border-color: @green1;
        box-shadow: 0px 1px 1px rgba(9, 9, 43, 0.2),
          0px 5px 10px rgba(0, 180, 93, 0.1);
        &:hover,
        &:focus {
          background-color: @green3;
          border-color: @green3;
          box-shadow: 0px 1px 1px rgba(4, 114, 47, 0.3),
            0px 5px 14px rgba(16, 137, 64, 0.5);
        }

        &:active {
          background-color: @green3;
          border-color: @green3;
          box-shadow: none;
        }

        &[disabled] {
          background-color: @gray3;
          border-color: @gray3;
          box-shadow: none;
          color: @gray4;
        }
      }

      &.btn-icon {
        background-color: @gray2;
        border: 0;
        line-height: 1;
        color: @gray5;
        box-shadow: none;
        text-shadow: none;

        &.ant-btn-icon-only {
          &.ant-btn-sm {
            & > * {
              font-size: 20px;
            }
          }
        }

        &:hover,
        &:focus {
          background-color: @gray3;
          border: 0;
          color: @blue1;
        }

        &:active {
          color: @gray4;
        }
      }
    }

    &.with-icon {
      .icon {
        font-size: 18px;
        vertical-align: -0.125em;

        &:first-child {
          padding-right: 4px;
        }

        &:last-child {
          padding-left: 4px;
        }
      }
    }

    &.ant-btn-link {
      &:not(.custom-link) {
        font-weight: @fontWeightSemiBold;

        &.with-icon {
          &.ant-btn-dangerous {
            .icon {
              color: @red1;
            }

            &:hover {
              color: @red1;
              background-color: rgba(@red1, 0.1);

              .icon {
                color: @red1;
              }
            }
          }
          .icon {
            color: @gray4;
            transition: @transition;
          }

          &:hover {
            .icon {
              color: @primary-color;
            }
          }
        }

        &:hover {
          color: @primary-color;
          background-color: rgba(@blue3, 0.1);
        }
      }

      &.custom-link {
        padding: 0;
        height: auto;
        border-bottom: 1px solid @gray3;
        border-radius: 0;
        line-height: 1;

        &:hover {
          &:not([disabled]) {
            color: @primary-color;
            border-bottom-color: @primary-color;
          }
        }

        &[disabled] {
          color: @gray4;
        }

        &.ant-btn-lg {
          line-height: 22px;
        }
      }
    }
  }
}
