.link {
  :global {
    .ant-btn {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;

      > span {
        display: inline;
      }
    }
  }
}
