@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff')
      format('woff'),
    url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.woff')
      format('woff'),
    url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.woff')
      format('woff'),
    url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff')
      format('woff'),
    url('../../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
